'use client';

import { useEffect, useState } from 'react'
import { AppShell, Burger, Button, MantineProvider, ScrollArea, createTheme } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { COOKIE_ITEMS, getCookieItem } from '@/utils/cookieControl'
import { API_ENDPOINT } from '@/config/endpoint/endpoint'
import dynamic from 'next/dynamic';
import { useDisclosure } from '@mantine/hooks';
import { images } from '@/utils/images';
import Link from 'next/link';
import { MENUS } from '@/config/menus';
import { usePathname } from 'next/navigation';
import themeVariables from '@/config/theme/themeVariable';
import { useAtom } from 'jotai';
import * as atoms from '@/config/store/globalStore'
import { DatesProvider } from '@mantine/dates';
import 'dayjs/locale/ko';
import '@mantine/dates/styles.css';


const Toaster = dynamic(() =>
  import('react-hot-toast').then((toaster) => toaster.Toaster)
);

const theme = createTheme({
  defaultRadius: "0.5rem",
});

export default function DefaultLayout({ children }: { children: React.ReactNode }) {
  const [opened, { toggle, close }] = useDisclosure();
  const pathname = usePathname();

  const [ user, setUser ] = useAtom(atoms.userInfo);

  useEffect(() => {
    checkUserInfo();
  }, []);
  
  async function checkUserInfo() {
    const signIn = getCookieItem(COOKIE_ITEMS.admin_signIn);
    if (pathname == '/login') return;
    try {
      const data = await API_ENDPOINT.account.checkUser();
      if (signIn !== 'true' || !data.role) {
        throw new Error('올바르지 않은 접근입니다.')
      }
      setUser(data);
    } catch {
      await API_ENDPOINT.account.logout();
      location.href = '/login'
    }
  }

  const MenuItem = ({ title, path }: { title: string, path: string }) => (
    <Link href={path} onClick={close}>
      <div className={`${pathname == path ? 'text-gray-800 font-semibold' : 'text-gray-600 font-normal'} transition-all text-base px-2 py-3 rounded-lg hover:text-gray-900`}>
        {title}
      </div>
    </Link>
  )

  return (
      <MantineProvider theme={theme}>
        <DatesProvider settings={{ locale: 'ko' }}>
        <ModalsProvider>
          <Toaster
            toastOptions={{ duration: 2000 }}
            containerStyle={{ fontSize: 14 }}
          />
          { pathname === '/login'
          ? <>{children}</>
          : <AppShell
              header={{ height: 60 }}
              navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
              transitionDuration={0}
              padding="md"
            >
              <AppShell.Header>
                <div className='flex h-full items-center gap-2 px-3'>
                  <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
                  <Link href={'/'} className="flex gap-1.5 items-center px-4" scroll={false}>
                    <img className="w-9 h-9" src={images.logo}/>
                    <div className="font-bold text-sm text-gray-900">데피니션랩</div>
                  </Link>
                  <Button
                    className='ml-auto px-2'
                    classNames={themeVariables.button.gray}
                    onClick={() => {
                      API_ENDPOINT.account.logout().then(() => {
                        location.href = '/login'
                      })
                    }}
                  >
                    로그아웃
                  </Button>
                </div>
              </AppShell.Header>
              <AppShell.Navbar>
                <ScrollArea p={16} h={'100%'} scrollbarSize={6} scrollHideDelay={500}>
                { ['admin'].includes(String(user.role)) &&
                  <>
                    <div className='text-[13px] text-gray-600 font-semibold mb-3 px-1'>어드민</div>
                    {
                      MENUS.filter((item) => item.role == 'admin')
                       .map((item, index) => <MenuItem key={item.title} title={item.title} path={item.path}/>)
                    }
                    <div className='pb-10 '/>
                  </>
                }

                { ['admin', 'operator'].includes(String(user.role)) &&
                  <>
                    <div className='text-[13px] text-gray-700 font-semibold mb-3 px-1'>오퍼레이터</div>
                    {
                      MENUS.filter((item) => item.role == 'operator')
                       .map((item, index) => <MenuItem key={item.title} title={item.title} path={item.path}/>)
                    }
                    <div className='pb-10 '/>
                  </>
                }

                
                { ['admin', 'user'].includes(String(user.role)) &&
                  <>
                    <div className='text-[13px] text-gray-700 font-semibold mb-3 px-1'>유저</div>
                    {
                      MENUS.filter((item) => item.role == 'user')
                       .map((item, index) => <MenuItem key={item.title} title={item.title} path={item.path}/>)
                    }
                    <div className='pb-10 '/>
                  </>
                }
                </ScrollArea>
              </AppShell.Navbar>
              <AppShell.Main>
                {children}
              </AppShell.Main>
            </AppShell>
          }
        </ModalsProvider>
        </DatesProvider>
      </MantineProvider>
  );
}
