import { atom } from "jotai";
import { INaverPlaceInfo } from "../endpoint/dtos/place";

export const signIn = atom<boolean | null>(null)

export const userInfo = atom<any>({
  user_id: '',
  role: ''
})

export const myPlaceInfo = atom<INaverPlaceInfo | undefined>(undefined);