import ky from 'ky';

export const CLIENT_ENDPOINT = process.env.NEXT_PUBLIC_CLIENT_ENDPOINT || 'https://www.definition-lab.com'
export const SERVER_ENDPOINT = process.env.NEXT_PUBLIC_SERVER_ENDPOINT || 'https://api.definition-lab.com'
export const STATIC_ENDPOINT = process.env.NEXT_PUBLIC_STATIC_ENDPOINT || 'https://static.definition-lab.com'

export const apiInstance = ky.create({
  prefixUrl: SERVER_ENDPOINT,
  credentials: 'include',
  timeout: 120000,
  retry: { limit: 3, delay: attemptCount => 3/2 ** attemptCount * 1000}
})

export const API_ENDPOINT = {
  statistics: {
    getStatistics: async () => {
      const result = await apiInstance.get(`admin/statistics`)
      const data = await result.json() as any;
      return data
    },
  },
  account: {
    checkUserUrl: `admin/account/check`,
    login: async (user_id: string, password: string) => {
      const json = { user_id, password }
      const result = await apiInstance.post(`admin/account/login`, { json });
      const data = await result.json() as any;
      return data
    },
    logout: async () => await apiInstance.post(`admin/account/logout`),
    getUsers: async () => {
      const result = await apiInstance.get(`admin/account/users`)
      const data = await result.json() as any;
      return data
    },
    updateUsers: async (body: any) => {
      const json = { ...body }
      const result = await apiInstance.post(`admin/account/users`, { json })
      const data = await result.json() as any;
      return data
    },
    checkUser: async () => {
      const result = await apiInstance.get(`admin/account/check`)
      const data = await result.json() as any;
      return data
    },
    getAdminUsers: async () => {
      const result = await apiInstance.get(`admin/account/admin-users`)
      const data = await result.json() as any;
      return data
    },
    addAdminUsers: async ( user_id: string, password: string, role: string, availableTraffic: number ) => {
      const json = { user_id, password, role, available_traffic: availableTraffic }
      const result = await apiInstance.post(`admin/account/admin-users`, { json })
      const data = await result.json() as any;
      return data
    },
    updateAdminUsers: async ( user_id: string, role: string, availableTraffic: number ) => {
      const json = { user_id, role, available_traffic: availableTraffic }
      const result = await apiInstance.put(`admin/account/admin-users`, { json })
      const data = await result.json() as any;
      return data
    },
    deleteAdminUsers: async (user_id: string) => {
      const result = await apiInstance.delete(`admin/account/admin-users/${user_id}`)
      const data = await result.json() as any;
      return data
    },
  },
  place: {
    getUsersPlaces: async (user_id: string) => {
      const result = await apiInstance.get(`admin/naver-place/places?user_id=${user_id}`)
      const data = await result.json() as any;
      return data
    },
    addUsersPlaces: async (user_id: string, url: string) => {
      const json = { user_id, url }
      const result = await apiInstance.post(`admin/naver-place/places`, { json })
      const data = await result.json() as any;
      return data
    },
    deleteUsersPlaces: async (id: string) => {
      const result = await apiInstance.delete(`admin/naver-place/places/${id}`)
      const data = await result.json() as any;
      return data
    },
    getReport: async (url?: string, user_id?: string) => {
      const query = new URLSearchParams()
      if (url) query.set('url', url)
      else if (user_id) query.set('user_id', user_id)
      const result = await apiInstance.get(`admin/naver-place/report?${query.toString()}`)
      const data = await result.json() as any;
      return data
    },
    resetPlaceCache: async (place_id: string, place_type: string) => {
      const json = { place_id, place_type}
      const result = await apiInstance.post(`admin/naver-place/reset-cache`, { json })
      const data = await result.json() as any;
      return data
    },
    getOperatorReport: async (url: string) => {
      const query = new URLSearchParams()
      if (url) query.set('url', url)
      const result = await apiInstance.get(`admin/o/naver-place/report?${query.toString()}`)
      const data = await result.json() as any;
      return data
    },
    getOperatorReportHistory: async () => {
      const result = await apiInstance.get(`admin/o/naver-place/report-history`)
      const data = await result.json() as any;
      return data
    },
    getOperatorPlaces: async () => {
      const result = await apiInstance.get(`admin/o/naver-place/my-places`)
      const data = await result.json() as any;
      return data
    },
    getOperatorCustomKeywords: async (placeId: string) => {
      const result = await apiInstance.get(`admin/o/naver-place/custom-keywords?place_id=${placeId}`)
      const data = await result.json() as any;
      return data
    },
    addOperatorCustomKeywords: async (placeId: string, keyword: string) => {
      const json = { place_id: placeId, keyword }
      const result = await apiInstance.post(`admin/o/naver-place/custom-keywords`, { json })
      const data = await result.json() as any;
      return data
    },
    deleteOperatorCustomKeywords: async (id: string) => {
      const result = await apiInstance.delete(`admin/o/naver-place/custom-keywords/${id}`)
      const data = await result.json() as any;
      return data
    },
    getCustomRanking: async (keyword: string, placeId: string, placeType: string) => {
      const result = await apiInstance.get(`admin/o/naver-place/custom-ranking?keyword=${keyword}&place_id=${placeId}&place_type=${placeType}`)
      const data = await result.json() as any;
      return data
    },
    addOperatorPlaces: async (user_id: string, url: string) => {
      const json = { user_id, url }
      const result = await apiInstance.post(`admin/o/naver-place/places`, { json })
      const data = await result.json() as any;
      return data
    },
    deleteOperatorPlaces: async (id: string) => {
      const result = await apiInstance.delete(`admin/o/naver-place/places/${id}`)
      const data = await result.json() as any;
      return data
    },
  },

  
  dashboard: {
    getKeywords: async (placeId: string) => {
      const result = await apiInstance.get(`admin/dashboard/keywords?user_id=${placeId}`)
      const data = await result.json() as any;
      return data
    },
    getRankingTrends: async (placeId: string) => {
      const result = await apiInstance.get(`admin/dashboard/ranking-trends?user_id=${placeId}`)
      const data = await result.json() as any;
      return data
    },
    getNewPlaces: async (placeId: string) => {
      const result = await apiInstance.get(`admin/dashboard/new-places?user_id=${placeId}`)
      const data = await result.json() as any;
      return data
    },
    getOperatorKeywords: async (placeId: string) => {
      const result = await apiInstance.get(`admin/o/dashboard/keywords?place_id=${placeId}`)
      const data = await result.json() as any;
      return data
    },
    getOperatorRankingTrends: async (placeId: string) => {
      const result = await apiInstance.get(`admin/o/dashboard/ranking-trends?place_id=${placeId}`)
      const data = await result.json() as any;
      return data
    },
    getOperatorNewPlaces: async (placeId: string) => {
      const result = await apiInstance.get(`admin/o/dashboard/new-places?place_id=${placeId}`)
      const data = await result.json() as any;
      return data
    }
  },

  messages: {
    sendReportMessage: async (phone: string, place_id: string) => {
      const json = { phone, place_id }
      const result = await apiInstance.post(`admin/messages/report`, { json })
      const data = await result.json() as any;
      return data
    },
  },

  traffic: {
    getTraffics: async () => {
      const result = await apiInstance.get(`admin/traffic`)
      const data = await result.json() as any;
      return data
    },
    getAllTrafficHistorys: async () => {
      const result = await apiInstance.get(`admin/traffic/history`)
      const data = await result.json() as any;
      return data
    },

    addOperatorTrafficPlace: async (placeId: string, searchKeyword: string, amount: number) => {
      const json = { place_id: placeId, search_keyword: searchKeyword, amount }
      const result = await apiInstance.post(`admin/o/traffic/place`, { json })
      const data = await result.json() as any;
      return data
    },
    getOperatorTrafficList: async () => {
      const result = await apiInstance.get(`admin/o/traffic/list`)
      const data = await result.json() as any;
      return data
    },
    getOperatorAvailableTraffic: async () => {
      const result = await apiInstance.get(`admin/o/traffic/available`)
      const data = await result.json() as any;
      return data
    },
    getOperatorTrafficHistory: async () => {
      const result = await apiInstance.get(`admin/o/traffic/history`)
      const data = await result.json() as any;
      return data
    },
    deleteOperatorTraffic: async (id: number) => {
      const result = await apiInstance.delete(`admin/o/traffic/place/${id}`)
      const data = await result.json() as any;
      return data
    }
  }
  
}