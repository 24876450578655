export const MENUS = [
  { title: '플레이스 리포트', path: '/a/report', role: 'admin' },
  { title: '대시보드', path: '/a/dashboard', role: 'admin' },
  { title: '유저 정보', path: '/a/users', role: 'admin' },
  { title: '어드민 유저 정보', path: '/a/admin-users', role: 'admin' },
  { title: '어드민 플레이스 설정', path: '/a/edit-places', role: 'admin' },
  { title: '메시지 전송', path: '/a/messages', role: 'admin' },
  { title: '캐시 초기화', path: '/a/reset-cache', role: 'admin' },
  { title: '트래픽 통계', path: '/a/traffic', role: 'admin' },

  { title: '플레이스 관리', path: '/o/my-places', role: 'operator' },
  { title: '대시보드', path: '/o/dashboard', role: 'operator' },
  { title: '리포트 분석', path: '/o/report', role: 'operator' },
  { title: '트래픽 관리', path: '/o/traffic', role: 'operator' },

  { title: '플레이스 관리', path: '/t/my-places', role: 'user' },
  { title: '대시보드', path: '/t/dashboard', role: 'user' },
  { title: '리포트 분석', path: '/t/report', role: 'user' },
  { title: '트래픽 관리', path: '/t/traffic', role: 'user' },
];